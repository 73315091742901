import { createContext, useContext, useEffect, useReducer } from "react";
import { authReducer } from "../reducers/AuthReducer";
import { googleLogout } from "@react-oauth/google";
import * as Sentry from "@sentry/nextjs";
import { clearGistCookies } from "../helper/gistIdentify";
import { getCookie } from "../helper/cookies";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const getUserDataFromLocalStorage = () => {
    let userData = {};
    if (typeof window !== "undefined") {
      userData = JSON.parse(localStorage.getItem("userData"));
    }

    return userData;
  };
  const userData = getUserDataFromLocalStorage();
  const initialSate = {
    everbeeName: userData?.name || "",
    everbeeToken: getCookie(`${process.env.NEXT_PUBLIC_AUTH_COOKIE_NAME}`) || "",
    everbeeEmail: userData?.email || "",
    everbeeUserId: userData?.id || "",
    navigateToEtsy: false,
    pricingPageCategory: userData?.pricing_page_category || "a",
    bogoOffer: userData?.bogo,
    planSubCategory: userData?.hobby_plan_sub_category || "",
    betaUser: userData?.is_print_beta || false,
    emailBeta: userData?.email_beta || false,
    limitForUser: userData?.limit_for_user || 10,
    productAnalyticsFeature:
      userData?.usage_details?.product_analytics_feature || 0,
    trialData: {
      trial_end_date: userData?.trial_end_date,
      trial_end_days_left: userData?.trial_end_days_left,
      trial_popup_to_show: userData?.trial_popup_to_show,
      trial_start_date: userData?.trial_start_date,
      trial_exp_category: userData?.trial_exp_category,
      on_free_trial: userData?.on_free_trial,
      total_trial_length: userData?.total_trial_length,
      on_free_trial: userData?.on_free_trial,
      trial_intro_popup: userData?.trial_intro_popup || false,
    },
    is_token_revoked: userData?.is_token_revoked,
    shop_name: userData?.shop_name,
    is_everbee_email_active: userData?.is_everbee_email_active,
    userImageUrl: userData?.image,
    productAnalyticsTableState: userData?.product_analytics_column_order,
    columnVisibilityModel: userData?.product_analytics_column_order?.columns
      ?.columnVisibilityModel || {
      whenMade: false,
      tagsUsed: false,
      autoRenews: false,
      isCustomizable: false,
      isPersonalizable: false,
      // descriptionCharacterCount: false,
      hasVariations: false,
      isSupply: false,
      minimumProcessing: false,
      placementOfListingInShop: false,
      shippedFrom: false,
      titleCharacter: false,
      whoMade: false,
      shopReviewRatio: false,
      listingTye: false,
      tags: false,
      shopDigitalListingCount: false,
      productLink: false,
      shopLink: false,
    },
    onboarding_flow: userData?.onboarding_flow,
    any_survey_answered_or_skipped_today:
      userData?.any_survey_answered_or_skipped_today || false,
    surveys_pending: userData?.surveys_pending || [],
    sidebar_onboarding_shown: userData?.sidebar_onboarding_shown,
    printBeta: userData?.is_print_beta,
    storeBeta: userData?.is_store_beta,
    upsell_test_group: userData?.upsell_group || ''
  };
  const [authState, authDispatch] = useReducer(authReducer, initialSate);

  const logoutUser = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("everbeeToken");
    localStorage.removeItem("everbeeEmail");
    localStorage.removeItem("everbeeUserId");
    localStorage.removeItem("everbeeName");
    localStorage.removeItem("everbeeForgotEmail");
    localStorage.removeItem("everbeePrevURL");
    localStorage.removeItem("everbeeFavCategory");
    localStorage.removeItem("printPrevPage");
    clearGistCookies();
    authDispatch({ type: "LOGOUT_USER", payload: initialSate });
    googleLogout();
    Sentry.setUser(null);
    window.location.href = `${process.env.NEXT_PUBLIC_AUTH_FRONTEND_APP}/logout?redirect_to=${process.env.NEXT_PUBLIC_CORE_FRONTEND_APP}`;
  };
  return (
    <AuthContext.Provider value={{ authState, authDispatch, logoutUser }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
