import { plans, plansPriceId } from "../../helper/priceconstant";

function addMonthsToDateFormatted(date, monthsToAdd) {
  const newDate = new Date(date);
  let newMonth = newDate.getMonth() + monthsToAdd;
  let newYear = newDate.getFullYear();

  if (newMonth >= 12) {
    newYear += Math.floor(newMonth / 12);
    newMonth %= 12;
  } else if (newMonth < 0) {
    newYear += Math.ceil(newMonth / 12);
    newMonth = 12 + (newMonth % 12);
  }

  newDate.setMonth(newMonth);
  newDate.setFullYear(newYear);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return newDate.toLocaleDateString(undefined, options);
}
const currentDate = new Date();
const subscriptionEndDate = addMonthsToDateFormatted(currentDate, 2);

export const BogoOffer = [
  {
    title: plans.growthmonth,
    price: "29.99",
    chargePrice: 29.99,
    subDesc: [`Price will be $29.99/mo", "after ${subscriptionEndDate}`],
    section: [
      {
        header: "Product Research Suite",
        description: [
          "<strong>Unlimited</strong> Product Analytics Searches per month",
          // "<strong>Unlimited</strong> Revenue Estimates",
          "All Tags of each particular listing",
          "Quick Sort Ability",
          "<strong>Unlimited</strong> Favorites folder uses",
          "Additional Listing Insights: Listing Reviews, Processing Time, Shipping Location + More",
          "<strong>Unlimited</strong> Shop Searches per month",
          "Additional Shop Insights: Shop Age, Shop Review Count + More",
        ],
      },
      {
        header: "Keyword Research Suite",
        description: [
          "<strong>Unlimited</strong> Keyword Searches per month",
          "Search Bar Keyword Volume Insights",
          "Keyword Finder - See Specific Keyword Insights + Related Keyword Ideas",
          "Tag Search Volume - See the monthly search volume of each particular tag on a listing",
          "Tag Analyzer - Insights of all the tags of each particular listing in one click",
        ],
      },
    ],
    buttonText: "Growth Monthly",
    buttonVariant: "outlined",
    plan: plans.growthmonth,
    priceId: plansPriceId.growthmonth,
    interval: "month",
    footerTagLine: `Price will be $29.99/mo after ${subscriptionEndDate}`,
  },
];

export const PricingContainerLg = [
  {
    title: plans.hobby,
    price: "0",
    mostPopular: false,
    subDesc: ["No credit card required"],
    section: [
      {
        header: "Product Research Suite",
        newFeature: false,
        description: [
          "Limited Product Analytics without premium metrics",
          "Quick Sort Ability",
          "Advanced Filters",
        ],
      },
    ],
    buttonText: "Free",
    buttonVariant: "outlined",
    interval: "month",
    plan: plans.hobby,
    noteReverseTrial:
      "When your trial ends, we’ll move you to the Free plan unless you choose to upgrade.",
  },
  {
    title: plans.hobby,
    price: "0",
    mostPopular: false,
    subDesc: ["No credit card required"],
    section: [
      {
        header: "Product Research Suite",
        newFeature: false,
        description: [
          "Limited Product Analytics without premium metrics",
          "Quick Sort Ability",
          "Advanced Filters",
        ],
      },
    ],
    buttonText: "Free",
    buttonVariant: "outlined",
    interval: "year",
    plan: plans.hobby,
    noteReverseTrial:
      "When your trial ends, we’ll move you to the Free plan unless you choose to upgrade.",
  },
  {
    title: plans.growthmonth,
    price: "29.99",
    mostPopular: true,
    chargePrice: 29.99,
    subDesc: ["Billed monthly", "Cancel anytime"],
    section: [
      {
        header: "Product Research Suite",
        newFeature: false,
        description: [
          "Product Analytics with Sales and Revenue data",
          "Premium metrics: Conversion Rate, Visiblity Score + More",
          "Quick Sort Ability",
          "Advanced Filters",
          "Unlimited Favorites Folder uses",
          "Additional Shop Insights: Shop Age, Shop Review Count + More",
        ],
      },
      {
        header: "Etsy Store Analytics",
        newFeature: false,
        description: [
          "Easy store Analytics: LTV, Repeat Rate, Avg. Order Value + More",
          "<strong>3</strong> Etsy Store connections",
        ],
      },
      {
        header: "Keyword Research Suite",
        newFeature: false,
        description: [
          "<strong>Unlimited</strong> Keyword Searches per month",
          "Search Bar Keyword Volume Insights",
          "Keyword Finder - See Specific Keyword Insights + Related Keyword Ideas",
          "Tag Search Volume - See the monthly search volume of each particular tag on a listing",
          "Tag Analyzer - Insights of all the tags of each particular listing in one click",
        ],
      },
      // {
      //   header: "Listing Rank Tracker",
      //   newFeature: true,
      //   description: [
      //     "Daily rankings of product listings for specific keywords on Etsy",
      //     "Monitors your listings automatically",
      //   ],
      // },
      // {
      //   header: "Trademark Monitor",
      //   newFeature: true,
      //   description: [
      //     "Scans your listings for any trademark infrigements on USPTO.gov",
      //   ],
      // },
    ],
    buttonText: "Upgrade to Growth",
    buttonVariant: "outlined",
    plan: plans.growthmonth,
    priceId: plansPriceId.growthmonth,
    interval: "month",
    footerTagLine: "Billed monthly. Cancel anytime.",
  },
  {
    title: plans.growthannualy,
    price: "19.99",
    chargePrice: 239.0,
    mostPopular: true,
    subDesc: ["$239.00 Billed annually", "4 Months Free!  $120 saved!"],
    section: [
      {
        header: "Product Research Suite",
        newFeature: false,
        description: [
          "Product Analytics with Sales and Revenue data",
          "Premium metrics: Conversion Rate, Visiblity Score + More",
          "Quick Sort Ability",
          "Advanced Filters",
          "Unlimited Favorites Folder uses",
          "Additional Shop Insights: Shop Age, Shop Review Count + More",
        ],
      },
      {
        header: "Etsy Store Analytics",
        newFeature: false,
        description: [
          "Easy store Analytics: LTV, Repeat Rate, Avg. Order Value + More",
          "<strong>3</strong> Etsy Store connections",
        ],
      },
      {
        header: "Keyword Research Suite",
        newFeature: false,
        description: [
          "<strong>Unlimited</strong> Keyword Searches per month",
          "Search Bar Keyword Volume Insights",
          "Keyword Finder - See Specific Keyword Insights + Related Keyword Ideas",
          "Tag Search Volume - See the monthly search volume of each particular tag on a listing",
          "Tag Analyzer - Insights of all the tags of each particular listing in one click",
        ],
      },
      // {
      //   header: "Listing Rank Tracker",
      //   newFeature: true,
      //   description: [
      //     "Daily rankings of product listings for specific keywords on Etsy",
      //     "Monitors your listings automatically",
      //   ],
      // },
      // {
      //   header: "Trademark Monitor",
      //   newFeature: true,
      //   description: [
      //     "Scans your listings for any trademark infrigements on USPTO.gov",
      //   ],
      // },
    ],
    buttonText: "Upgrade to Growth",
    buttonVariant: "outlined",
    plan: plans.growthannualy,
    priceId: plansPriceId.growthannualy,
    interval: "year",
    footerTagLine: "$239 Billed annually. ($19.99 per month)",
  },
  {
    title: plans.businessmonth,
    price: "99",
    chargePrice: 99,
    mostPopular: false,
    subDesc: ["Billed monthly", "Cancel anytime"],
    section: [
      {
        header: "Everything in Growth Plan +",
        newFeature: false,
        description: [
          "<strong>Unlimited</strong> Etsy Store Connections",
          "Priority chat and email support",
        ],
      },
      // {
      //   header: "Trademark Monitor",
      //   newFeature: true,
      //   description: [
      //     "<strong>Unlimited</strong> Listings Tracked across all shops",
      //     "<strong>Automatically</strong> scans your listings for any trademarked infrigements on USPTO.gov",
      //   ],
      // },
    ],
    buttonText: "Upgrade to Business",
    buttonVariant: "outlined",
    plan: plans.businessmonth,
    priceId: plansPriceId.businessmonth,
    interval: "month",
    footerTagLine: "Billed monthly. Cancel anytime.",
  },
  {
    title: plans.businessannualy,
    price: "69",
    chargePrice: 828.0,
    mostPopular: false,
    subDesc: ["$828.00 Billed annually", "4 Months Free!  $360 saved!"],
    section: [
      {
        header: "Everything in Growth Plan +",
        newFeature: false,
        description: [
          "<strong>Unlimited</strong> Etsy Store Connections",
          "Priority chat and email support",
        ],
      },
      // {
      //   header: "Trademark Monitor",
      //   newFeature: true,
      //   description: [
      //     "<strong>Unlimited</strong> Listings Tracked across all shops",
      //     "<strong>Automatically</strong> scans your listings for any trademarked infrigements on USPTO.gov",
      //   ],
      // },
    ],
    buttonText: "Upgrade to Business",
    buttonVariant: "outlined",
    plan: plans.businessannualy,
    priceId: plansPriceId.businessannualy,
    interval: "year",
    footerTagLine: "$828 Billed annually. ($360 SAVINGS = 4 months value)",
  },
];

export const PricingContainerSm = [
  {
    title: plans.growthmonth,
    price: "29.99",
    mostPopular: true,
    chargePrice: 29.99,
    subDesc: ["Billed monthly", "Cancel anytime"],
    section: [
      {
        header: "Product Research Suite",
        newFeature: false,
        description: [
          "Product Analytics with Sales and Revenue data",
          "Premium metrics: Conversion Rate, Visiblity Score + More",
          "Quick Sort Ability",
          "Advanced Filters",
          "Unlimited Favorites Folder uses",
          "Additional Shop Insights: Shop Age, Shop Review Count + More",
        ],
      },
      {
        header: "Etsy Store Analytics",
        newFeature: false,
        description: [
          "Easy store Analytics: LTV, Repeat Rate, Avg. Order Value + More",
          "<strong>3</strong> Etsy Store connections",
        ],
      },
      {
        header: "Keyword Research Suite",
        newFeature: false,
        description: [
          "<strong>Unlimited</strong> Keyword Searches per month",
          "Search Bar Keyword Volume Insights",
          "Keyword Finder - See Specific Keyword Insights + Related Keyword Ideas",
          "Tag Search Volume - See the monthly search volume of each particular tag on a listing",
          "Tag Analyzer - Insights of all the tags of each particular listing in one click",
        ],
      },
      // {
      //   header: "Listing Rank Tracker",
      //   newFeature: true,
      //   description: [
      //     "Daily rankings of product listings for specific keywords on Etsy",
      //     "Monitors your listings automatically",
      //   ],
      // },
      // {
      //   header: "Trademark Monitor",
      //   newFeature: true,
      //   description: [
      //     "Scans your listings for any trademark infrigements on USPTO.gov",
      //   ],
      // },
    ],
    buttonText: "Upgrade to Growth",
    buttonVariant: "outlined",
    plan: plans.growthmonth,
    priceId: plansPriceId.growthmonth,
    interval: "month",
    footerTagLine: "Billed monthly. Cancel anytime.",
  },
  {
    title: plans.growthannualy,
    price: "19.99",
    chargePrice: 239.0,
    mostPopular: true,
    subDesc: ["$239.00 Billed annually", "4 Months Free!  $120 saved!"],
    section: [
      {
        header: "Product Research Suite",
        newFeature: false,
        description: [
          "Product Analytics with Sales and Revenue data",
          "Premium metrics: Conversion Rate, Visiblity Score + More",
          "Quick Sort Ability",
          "Advanced Filters",
          "Unlimited Favorites Folder uses",
          "Additional Shop Insights: Shop Age, Shop Review Count + More",
        ],
      },
      {
        header: "Etsy Store Analytics",
        newFeature: false,
        description: [
          "Easy store Analytics: LTV, Repeat Rate, Avg. Order Value + More",
          "<strong>3</strong> Etsy Store connections",
        ],
      },
      {
        header: "Keyword Research Suite",
        newFeature: false,
        description: [
          "<strong>Unlimited</strong> Keyword Searches per month",
          "Search Bar Keyword Volume Insights",
          "Keyword Finder - See Specific Keyword Insights + Related Keyword Ideas",
          "Tag Search Volume - See the monthly search volume of each particular tag on a listing",
          "Tag Analyzer - Insights of all the tags of each particular listing in one click",
        ],
      },
      // {
      //   header: "Listing Rank Tracker",
      //   newFeature: true,
      //   description: [
      //     "Daily rankings of product listings for specific keywords on Etsy",
      //     "Monitors your listings automatically",
      //   ],
      // },
      // {
      //   header: "Trademark Monitor",
      //   newFeature: true,
      //   description: [
      //     "Scans your listings for any trademark infrigements on USPTO.gov",
      //   ],
      // },
    ],
    buttonText: "Upgrade to Growth",
    buttonVariant: "outlined",
    plan: plans.growthannualy,
    priceId: plansPriceId.growthannualy,
    interval: "year",
    footerTagLine: "$239 Billed annually. ($19.99 per month)",
  },
  {
    title: plans.businessmonth,
    price: "99",
    chargePrice: 99,
    mostPopular: false,
    subDesc: ["Billed monthly", "Cancel anytime"],
    section: [
      {
        header: "Everything in Growth Plan +",
        newFeature: false,
        description: [
          "<strong>Unlimited</strong> Etsy Store Connections",
          "Priority chat and email support",
        ],
      },
      // {
      //   header: "Trademark Monitor",
      //   newFeature: true,
      //   description: [
      //     "<strong>Unlimited</strong> Listings Tracked across all shops",
      //     "<strong>Automatically</strong> scans your listings for any trademarked infrigements on USPTO.gov",
      //   ],
      // },
    ],
    buttonText: "Upgrade to Business",
    buttonVariant: "outlined",
    plan: plans.businessmonth,
    priceId: plansPriceId.businessmonth,
    interval: "month",
    footerTagLine: "Billed monthly. Cancel anytime.",
  },
  {
    title: plans.businessannualy,
    price: "69",
    chargePrice: 828.0,
    mostPopular: false,
    subDesc: ["$828.00 Billed annually", "4 Months Free!  $360 saved!"],
    section: [
      {
        header: "Everything in Growth Plan +",
        newFeature: false,
        description: [
          "<strong>Unlimited</strong> Etsy Store Connections",
          "Priority chat and email support",
        ],
      },
      // {
      //   header: "Trademark Monitor",
      //   newFeature: true,
      //   description: [
      //     "<strong>Unlimited</strong> Listings Tracked across all shops",
      //     "<strong>Automatically</strong> scans your listings for any trademarked infrigements on USPTO.gov",
      //   ],
      // },
    ],
    buttonText: "Upgrade to Business",
    buttonVariant: "outlined",
    plan: plans.businessannualy,
    priceId: plansPriceId.businessannualy,
    interval: "year",
    footerTagLine: "$828 Billed annually. ($360 SAVINGS = 4 months value)",
  },
  {
    title: plans.hobby,
    price: "0",
    mostPopular: false,
    subDesc: ["No credit card required"],
    section: [
      {
        header: "Product Research Suite",
        newFeature: false,
        description: [
          "Limited Product Analytics without premium metrics",
          "Quick Sort Ability",
          "Advanced Filters",
        ],
      },
    ],
    buttonText: "Free",
    buttonVariant: "outlined",
    interval: "month",
    plan: plans.hobby,
    noteReverseTrial:
      "When your trial ends, we’ll move you to the Free plan unless you choose to upgrade.",
  },
  {
    title: plans.hobby,
    price: "0",
    mostPopular: false,
    subDesc: ["No credit card required"],
    section: [
      {
        header: "Product Research Suite",
        newFeature: false,
        description: [
          "Limited Product Analytics without premium metrics",
          "Quick Sort Ability",
          "Advanced Filters",
        ],
      },
    ],
    buttonText: "Free",
    buttonVariant: "outlined",
    interval: "year",
    plan: plans.hobby,
    noteReverseTrial:
      "When your trial ends, we’ll move you to the Free plan unless you choose to upgrade.",
  },
];

export const TrialPricing = [
  {
    title: plans.growthmonth,
    price: "29.99",
    mostPopular: true,
    chargePrice: 29.99,
    subDesc: ["Billed monthly", "Cancel anytime"],
    section: [],
    buttonText: "Upgrade to Growth",
    buttonVariant: "outlined",
    plan: plans.growthmonth,
    priceId: plansPriceId.growthmonth,
    interval: "month",
    footerTagLine: "Billed monthly. Cancel anytime.",
  },
  {
    title: plans.growthannualy,
    price: "19.99",
    chargePrice: 239.0,
    mostPopular: true,
    subDesc: ["$239.00 Billed annually", "4 Months Free!  $120 saved!"],
    section: [],
    buttonText: "Upgrade to Growth",
    buttonVariant: "outlined",
    plan: plans.growthannualy,
    priceId: plansPriceId.growthannualy,
    interval: "year",
    footerTagLine: "$239 Billed annually. ($19.99 per month)",
  },
];
