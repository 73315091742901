import axios from "axios";
import { toast } from "react-toastify";
import amplitude from "amplitude-js";
import { getUserProperties } from "../components/utils/userProperties";
import { googleAnalyticsEvent, rewardfulCookies } from "../apis/account";
import * as Sentry from "@sentry/nextjs";
import { clearGistCookies, gistIdentify } from "./gistIdentify";

const verifyUser = async (
  token,
  email,
  onboardingstep,
  step,
  redirectToEtsy,
  redirectToPricing,
  redirectToAccounts,
  router,
  shopConnectSuccess,
  redirectToThankyou,
  connectToEtsy,
  new_user,
  magicLink,
  redirectToPrint = false,
  redirectToEmail = false,
  redirectToDashboard = false,
  openMonthlyCheckout = false,
  openAnuallyCheckout = false,
  connectShop = false,
  print_redirect
) => {
  localStorage.removeItem("userData");
  localStorage.removeItem("everbeeToken");
  localStorage.removeItem("everbeeEmail");
  localStorage.removeItem("everbeeUserId");
  localStorage.removeItem("everbeeName");
  localStorage.removeItem("everbeeForgotEmail");
  localStorage.removeItem("everbeePrevURL");
  localStorage.removeItem("everbeeFavCategory");
  localStorage.removeItem("printPrevPage");
  clearGistCookies();
  try {
    const { data } = await axios.get(
      `${
        process.env.NEXT_PUBLIC_EXT_APP
      }/everbee-web?user_id=${token}&user_email=${encodeURIComponent(email)}`
    );
    localStorage.setItem("userData", JSON.stringify(data));
    localStorage.setItem("everbeeToken", data.authentication_token);
    localStorage.setItem("everbeeEmail", data.email);
    gistIdentify(data.email);
    if (new_user == "true") {
      var userProperties = getUserProperties(data);
      await amplitude
        .getInstance()
        .init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, data?.id);
      await amplitude.getInstance().setUserProperties(userProperties);
      await amplitude.getInstance().logEvent("Signup", {
        method: "Etsy",
      });
      googleAnalyticsEvent({
        eventName: "Signup",
        eventPrams: { method: "Etsy" },
      });
      //revgems lead
      revgems &&
        revgems("lead", {
          user_id: data?.id,
          name: data?.name,
          email: data?.email,
        });
      await rewardfulCookies();
    } else if (new_user == "false") {
      var userProperties = getUserProperties(data);
      amplitude
        .getInstance()
        .init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, data?.id);
      amplitude.getInstance().setUserProperties(userProperties);
      amplitude.getInstance().logEvent("Signin", {
        method: "Etsy",
      });
      googleAnalyticsEvent({
        eventName: "Signin",
        eventPrams: { method: "Etsy" },
      });
    }
    Sentry.setUser({ email: data?.email });
    if (shopConnectSuccess) {
      // Changes needs to be done
      //need to add a page to have this event fired for SSO
      var userProperties = getUserProperties(data);
      amplitude
        .getInstance()
        .init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, data?.id);
      amplitude.getInstance().setUserProperties(userProperties);
      amplitude.getInstance().logEvent("Connect Store", {
        type_of_user: data?.sales_channels[data.sales_channels.length - 1]
          ?.sales_channel_shop_name
          ? "Seller"
          : "Non-Seller",
        shop_name: data?.sales_channels[data.sales_channels.length - 1]
          ?.sales_channel_shop_name
          ? data?.sales_channels[data.sales_channels.length - 1]
              .sales_channel_shop_name
          : "",
      });
      googleAnalyticsEvent({
        eventName: "ConnectStore",
        eventPrams: {
          type_of_user: data?.sales_channels[data.sales_channels.length - 1]
            ?.sales_channel_shop_name
            ? "Seller"
            : "Non-Seller",
          shop_name: data?.sales_channels[data.sales_channels.length - 1]
            ?.sales_channel_shop_name
            ? data?.sales_channels[data.sales_channels.length - 1]
                .sales_channel_shop_name
            : "",
        },
      });
      //
    }

    if (redirectToEtsy && connectToEtsy) {
      window.location.href = "/account?redirectToEtsy=true";
    } else if (redirectToDashboard) {
      window.location.href = "/";
    } else if (redirectToEtsy) {
      window.location.href = `https://www.etsy.com`;
    } else if (redirectToAccounts) {
      if (openMonthlyCheckout || openAnuallyCheckout) {
        window.location.href = `/account?tabname=profile&redirectToEtsy=true&openMonthlyCheckout=${openMonthlyCheckout}&openAnuallyCheckout=${openAnuallyCheckout}`; //
      } else if (connectShop) {
        window.location.href = "/account?connectShop=true";
      } else {
        window.location.href = "/account";
      }
    } else if (redirectToThankyou) {
      window.location.href = "https://everbee.io/install/";
    } else if (print_redirect) {
      window.location.href = `/everbeePrint?print_redirect=${print_redirect}`;
    } else if (redirectToPrint) {
      window.location.href = "/everbeePrint";
    } else if (redirectToEmail) {
      window.location.href = "/evermail";
    } else if (!magicLink) {
      window.location.href =
        "/productAnalytics?tab=analyze_by_term&search_term=";
    }
  } catch (err) {
    console.log(err, "err");
    toast.error("Unauthorized access.");
  }
};

export default verifyUser;
