import React, { useState, useEffect } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  stripeSubscription,
  promoCodeDetails,
  checkAndUpdateSubscription,
  assignTestGroup,
} from "../../apis/stripe";
import { loadStripe } from "@stripe/stripe-js";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { useLoader } from "../../contexts/LoaderContext";
import amplitude from "amplitude-js";
import { googleAnalyticsEvent } from "../../apis/account";
import { useSalesChannel } from "../../contexts/SalesChannelContext";

export default function CheckoutModal({
  priceId,
  details,
  offer,
  setOpenPurchaseModal,
  setOpenCardFailModal,
}) {
  const stripe = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PK_LIVE);
  return (
    <Elements stripe={stripe}>
      <CheckoutForm
        priceId={priceId}
        details={details}
        offer={offer}
        setOpenPurchaseModal={setOpenPurchaseModal}
        setOpenCardFailModal={setOpenCardFailModal}
      />
    </Elements>
  );
}

const CheckoutForm = ({
  priceId,
  details,
  offer,
  setOpenPurchaseModal,
  setOpenCardFailModal,
}) => {
  const {
    authDispatch,
    authState: { everbeeName, everbeeEmail, everbeeUserId, upsell_test_group },
  } = useAuth();
  const { loadingSet, setLoadingSet, addToLoadingSet, removeFromLoadingSet } =
    useLoader();
  const {
    getAccountDetails , salesChannelsState: { userAccountDetails },
  } = useSalesChannel();
  const router = useRouter();
  const { redirect_to } = router.query;
  const [promoCode, setPromoCode] = useState();
  const [promoCodeId, setPromoCodeId] = useState();
  const [promoCodeName, setPromoCodeName] = useState();
  const [promoCodePercent, setPromoCodePercent] = useState();
  const [havePromoCode, setHavePromoCode] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [loadingPromoCode, setLoadingPromoCode] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const threedAuthPayment = async (secret) => {
    const { paymentIntent, error } = await stripe.confirmCardPayment(secret);
    if (error) {
      amplitude.getInstance().logEvent(`Upgrade`, {
        plan_name: details.title,
        previous_plan_name: userAccountDetails?.current_plan,
        status: "failed",
        reason: `Payment Intent failed, please try again later.`,
      });
      googleAnalyticsEvent({
        eventName: "Upgrade",
        eventPrams: {
          plan_name: details.title,
          previous_plan_name: userAccountDetails?.current_plan,
          status: "failed",
          reason: `Payment Intent failed, please try again later.`,
        },
      });
      removeFromLoadingSet("makePayment");
      setPaymentLoading(false);
      setOpenCardFailModal([details]);
      return toast.error("Error in payment, please try again later.");
    }
    if (paymentIntent.status === "succeeded") {
      amplitude.getInstance().logEvent(`Upgrade`, {
        plan_name: details.title,
        previous_plan_name: userAccountDetails?.current_plan,
        status: "success",
      });
      googleAnalyticsEvent({
        eventName: "Upgrade",
        eventPrams: {
          plan_name: details.title,
          previous_plan_name: userAccountDetails?.current_plan,
          status: "success",
        },
      });
      callbackToUpdateSubscription();
      if (details?.priceId === process.env.NEXT_PUBLIC_GROWTH_MONTH) {
        const res = await assignTestGroup();
        if (res.data) {
          authDispatch({
            type: "ASSIGN_UPSELL_TEST_GROUP",
            payload: res.data.test_group,
          });
        await getAccountDetails();
          const url = redirect_to
            ? `/upsell-promo?redirect_to=${redirect_to}`
            : "/upsell-promo";
          router.push(url);
        }
      } else {
        if (redirect_to) {
          window.location.href = redirect_to;
        } else {
          router.push("https://everbee.io/congratulations/");
        }
      }
    }
  };

  const callbackToUpdateSubscription = async () => {
    try {
      const res = await checkAndUpdateSubscription();
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const applyPromoCode = async (e) => {
    e.preventDefault();
    if (details.title == "Pro") {
      return toast.error("Invalid promo code for Pro Plan");
    }

    setLoadingPromoCode(true);

    try {
      addToLoadingSet("applyPromoCode");
      const res = await promoCodeDetails(promoCode);
      setPromoCodeId(res.data.promo_code.id);
      setPromoCodeName(res.data.promo_code.code);
      setPromoCodePercent(res.data.promo_code.coupon.percent_off);
      setLoadingPromoCode(false);
    } catch (err) {
      setPromoCodeId();
      setPromoCodePercent();
      setLoadingPromoCode(false);
      toast.error("Invalid promo code");
    } finally {
      removeFromLoadingSet("applyPromoCode");
    }
  };

  const makePayment = async (e) => {
    e.preventDefault();
    addToLoadingSet("makePayment");
    setPaymentLoading(true);
    await stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: everbeeName,
          email: everbeeEmail,
        },
      })
      .then(async function (result) {
        var referral_code = null;
        try {
          if (Rewardful.referral && Rewardful.referral.length > 0) {
            referral_code = Rewardful.referral;
          }
          const paymentMethodId = result.paymentMethod.id;
          const response = await stripeSubscription({
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            promoCodeId: promoCodeId,
            referral_code: referral_code,
            affiliate: offer ? true : false,
          });
          rewardful("convert", { email: everbeeEmail });
          if (response.status == 200) {
            if (response.data.message == "Authentication required") {
              threedAuthPayment(response.data.client_secret);
            } else {
              // amplitude.getInstance().setUserProperties({ out_of_limit: false });
              amplitude.getInstance().logEvent(`Upgrade`, {
                plan_name: details.title,
                previous_plan_name: userAccountDetails?.current_plan,
                status: "success",
              });
              googleAnalyticsEvent({
                eventName: "Upgrade",
                eventPrams: {
                  plan_name: details.title,
                  previous_plan_name: userAccountDetails?.current_plan,
                  status: "success",
                },
              });
              if (offer) {
                setOpenPurchaseModal(true);
              } else {
                if (details?.priceId === process.env.NEXT_PUBLIC_GROWTH_MONTH) {
                  const res = await assignTestGroup();
                  if (res.data) {
                    authDispatch({
                      type: "ASSIGN_UPSELL_TEST_GROUP",
                      payload: res.data.test_group,
                    });
                 await getAccountDetails();
                    const url = redirect_to
                      ? `/upsell-promo?redirect_to=${redirect_to}`
                      : "/upsell-promo";
                    router.push(url);
                  }
                } else {
                  if (redirect_to) {
                    window.location.href = redirect_to;
                  } else {
                    router.push("https://everbee.io/congratulations/");
                  }
                }
              }
            }
          }
        } catch (err) {
          amplitude.getInstance().logEvent(`Upgrade`, {
            plan_name: details.title,
            previous_plan_name: userAccountDetails?.current_plan,
            status: "failed",
            reason: `${
              err.response?.data?.message ||
              "Unable to process payment. Please try a different card."
            }`,
          });
          setPaymentLoading(false);
          // toast.error(
          //   err.response?.data?.message ||
          //     "Unable to process payment. Please try a different card."
          // );
          setOpenCardFailModal([details]);
        } finally {
          removeFromLoadingSet("makePayment");
        }
      });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#f7f8fa",
          margin: 0,
          padding: 0,
          borderRadius: "5px",
        }}
      >
        <div style={{}}>
          <form
            style={{
              display: "block",
              width: "100%",
              margin: 0,
            }}
          >
            <div className="checkout-form-header">
              {!offer ? "Upgrade membership" : "Bogo Plan Details"}
            </div>
            <div className="checkout-form-plan-details">
              <div>
                <div
                  style={{
                    fontWeight: offer ? "600" : "400",
                    fontSize: offer ? "16px" : "",
                  }}
                >
                  {!offer ? details.title : "Buy one, get one month free"}
                </div>{" "}
                <div className="checkout-plan-duration">
                  <span ms-lang="pay_billed">{details.footerTagLine}</span>
                </div>
              </div>
              <div className="ms-checkout-pricing-wrapper">
                <div
                  data-cy="amount"
                  className="ms-checkout-price"
                  style={{ fontWeight: offer ? "600" : "400" }}
                >
                  {`$${details.chargePrice}`}
                </div>
              </div>
            </div>
            {promoCodeId && (
              <div className="checkout-form-plan-details">
                <div>
                  <div>{promoCodeName}</div>{" "}
                  <div className="checkout-plan-duration">
                    <span ms-lang="pay_billed">Sub total</span>
                  </div>
                </div>
                <div className="ms-checkout-pricing-wrapper">
                  <div
                    data-cy="amount"
                    className="ms-checkout-price"
                    style={{}}
                  >
                    {promoCodePercent &&
                      `- $${(
                        details.chargePrice *
                        (promoCodePercent / 100)
                      ).toFixed(2)}`}
                  </div>
                  <div
                    data-cy="amount"
                    className="ms-checkout-price"
                    style={{}}
                  >
                    {promoCodePercent &&
                      `=  $${(
                        details.chargePrice -
                        details.chargePrice * (promoCodePercent / 100)
                      ).toFixed(2)}`}
                  </div>
                </div>
              </div>
            )}

            {havePromoCode ? (
              <>
                {promoCodeId ? (
                  <div className="checkout-form-div">
                    <Link
                      style={{ fontSize: "0.7rem", cursor: "pointer" }}
                      onClick={() => {
                        setPromoCodeName();
                        setPromoCodeId();
                        setPromoCodePercent();
                        setHavePromoCode(false);
                      }}
                    >
                      Remove promo code
                    </Link>
                  </div>
                ) : (
                  <Paper
                    className="checkout-form-div"
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="COUPON"
                      onChange={(e) => setPromoCode(e.target.value)}
                      inputProps={{ "aria-label": "search google maps" }}
                    />
                    <Button
                      style={{
                        margin: "5px",
                        color: "white",
                        backgroundColor: "#58b4f4",
                      }}
                      disabled={loadingPromoCode}
                      variant="contained"
                      onClick={applyPromoCode}
                    >
                      Apply
                    </Button>
                  </Paper>
                )}
              </>
            ) : (
              <div className="checkout-form-div">
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => setHavePromoCode(true)}
                >
                  Have a promo code?
                </Link>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="checkout-form-card-header">
                <p>New Credit or Debit Card</p>
                <div>
                  <img src="/visa.png" />
                  <img src="/mastercard.png" />
                  <img src="/americanexpress.png" />
                </div>
              </div>
              <CardElement
                className="card checkout-form-card-input"
                options={{
                  style: {
                    base: {
                      backgroundColor: "white",
                      marginTop: "20px",
                      fontSize: "15px",
                    },
                  },
                }}
              />

              <button
                onClick={makePayment}
                style={{ color: "white", backgroundColor: "#58b4f4" }}
                className="pay-button"
                disabled={isPaymentLoading}
              >
                {isPaymentLoading ? "Loading..." : "Pay"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
