import Image from "next/image";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import SideMainMenu from "../SideMainMenu/SideMainMenu";
import SidebarSwitch from "./SidebarSwitch";
// import { SUB_MENU_LIST, MAIN_MENU } from "../../constants/menuList.js";
import StyledWrapper from "./StyledWrapper";
import SideSubMenu from "../SideSubMenu/SideSubMenu";
import logoIcon from "../../assets/svg/everbee-logo.svg";
import logoIconSm from "../../assets/svg/everbee-logo-sm.svg";
import dashboardIcon from "../../assets/svg/dashboard.svg";
import researchTools from "../../assets/svg/research-tools.svg";
import learnIcon from "../../assets/svg/learn-icon.svg";
import everEmailIcon from "../../assets/svg/ever-email.svg";
import everPrintIcon from "../../assets/svg/ever-print.svg";
import addToChrome from "../../assets/svg/add-to-chrome.svg";
import addToChromeSm from "../../assets/svg/chrome.svg";
import optimize from "../../assets/svg/optimize.svg";
import accountsIcon from "../../assets/svg/accounts.svg";
import pricingIcon from "../../assets/svg/pricing.svg";
import affiliateIcon from "../../assets/svg/affiliate.svg";
import supportIcon from "../../assets/svg/sup.svg";
import featureRequestIcon from "../../assets/svg/feature.svg";
import training from "../../assets/svg/training.svg";
import thrive from "../../assets/svg/thrive.svg";
import ProductAnalytics from "../ProductAnalyticsNew/ProductAnalyticsNew";
import { useState, useEffect, useRef } from "react";
import StoreSelect from "../StoreSelect/StoreSelect";
import ShopSelector from "../dashboardComponents/ShopSelector";
import styled from "styled-components";
import PrintIcon from "@mui/icons-material/Print";
import { useSalesChannel } from "../../contexts/SalesChannelContext";
import ToggleGroup from "./ToggleGroup";
import YoutubeIcon from "../../assets/svg/youtube.svg";
import CommunityIcon from "../../assets/svg/community.svg";
import { PlanViewSideBar } from "../PlanViewSidebar";
import { useExtension } from "../../contexts/ExtensionContext";
import { Avatar, Box, Typography, Tooltip } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import avatarPic from "../../assets/images/test-avatar.jpg";
import { ReconnectModal } from "../ReconnectModal";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import up from "../../assets/svg/up.svg";
import { UserCardInfo } from "./UserCardInfo";
import ArrowUpDown from "../../assets/svg/arrow-up-down.svg";
import AccessExtension from "../../assets/svg/accessExtension.svg";
// import ambassadorsIcon from "../../assets/svg/ambassadors-icon.svg";
import coursesIcon from "../../assets/svg/courses-icon.svg";
import arrowUp from "../../assets/svg/arrow_up.svg";
import productSelector from "../../assets/svg/selecter_menu.svg";
import ProductSelector from "../ProductSelector/ProductSelector";
import researchBee from "../../assets/svg/research_logo.svg";
import arrowDown from "../../assets/svg/arrow_down.svg";
import { useRouter } from "next/router";
import { startObjectsIntroTooltip } from "../../helper/selectorSteps";

export const MAIN_MENU = {
  DASHBOARD: "dashboard",
  RESEARCH_TOOLS: "research-tools",
  OPTIMIZE: "optimize",
  LEARN: "learn",
  EVERBEEMAIL: "everbee-email",
  EVERPRINT: "everbee-print",
  ACCOUNT: "account",
  TRAINING: "training",
  WISHLIST: "wishlist",
  COURSES: "courses",
  // AMBASSADORS: "ambassadors",
  AFFILIATE: "affiliate",
  PRICING: "pricing-plans",
  SUPPORT: "support",
  YOUTUBE: "youtube",
  COMMUNITY: "community",
  FEATURE_REQUEST: "feature-request",
};

export const SUB_MENU_LIST = {
  RESEARCH_TOOLS: [
    {
      name: "Product Analytics",
      slug: "productAnalytics?tab=analyze_by_term&search_term=",
      check: false,
    },
    {
      name: "Shop Analyzer",
      slug: "productAnalytics?tab=analyze_by_shop&search_term=&defaultShop=true&search_shop=&start_search=false",
      check: false,
    },
    {
      name: "Keyword Research",
      slug: "keywordResearch?default_table=true",
      check: false,
    },
    { name: "Favorites", slug: "favorites", check: false },
    { name: "My Listings", slug: "myListings", check: false },
    { name: "Etsy Calculator", slug: "etsyCalculator", check: false },
  ],
  OPTIMIZE: [
    { name: "Listing Rank Tracker", slug: "listingRankTracker", check: true },
    { name: "Trademark Monitor", slug: "tradeMarkMonitor", check: true },
  ],
};

export const SUB_MENU = {
  PRODUCT_ANALYTICS: "product-analytics",
  KEYWORD_RESEARCH: "keyword-research",
  SHOP_ANALYZER: "shop-analyzer",
  ETSY_CALCULATOR: "etsy-calculator",
  WATCHLIST: "watchlist",
  MY_LISTINGS: "my-listings",
  LIST_RANK_TRACKER: "list-rank-tracker",
  LISTING_AUDIT: "listing-audit",
  LISTING_BUILDER: "listing-builder",
  REVIEW_GATHER: "review-gather",
  TRADEMARK_MONITOR: "trademark-monitor",
  EVERBEE_EMAIL: "everbee-email",
};

const Sidebar = ({ isExpanded, setIsExpanded, hideCollapse, tabView }) => {
  const router = useRouter();
  // const [isExpanded, setIsExpanded] = useState(true);
  const [alignment, setAlignment] = useState("left");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const animationOptions = {
    initialOptions: {
      // opacity: 0,
      height: 0,
    },
    animateOptions: {
      // opacity: 1,
      height: "auto",
    },
    exitOptions: {
      height: 0,
      // opacity: 0,
    },
  };
  const {
    authDispatch,
    authState: {
      everbeeName,
      everbeeEmail,
      everbeeToken,
      everbeeUserId,
      trialData,
      userImageUrl,
      sidebar_onboarding_shown,
      onboarding_flow
    },
  } = useAuth();
  const {
    salesChannelsState: {
      salesChannelsDetails,
      sellerSalesChannelsDetails,
      userAccountDetails,
      selectedStores,
    },
    updateSelectedSalesChannels,
    salesChannelsDispatch,
    setStoreSelected,
  } = useSalesChannel();

  const [menuState, setMenuState] = useState(MAIN_MENU.RESEARCH_TOOLS);
  // const [selectedStore, setSelectedStore] = useState();
  const [storeList, setStoreList] = useState([
    {
      name: "Maria's Jewelery",
      isChecked: false,
      sales: 2346,
      avatar: "M",
    },
  ]);
  const { extInstalled } = useExtension();

  const goToEtsy = () => {
    if (extInstalled) {
      window.location.href = `https://www.etsy.com`;
    } else {
      window.open("https://everbee.io/install/", "_blank");
    }
  };
  const isUser =
    userAccountDetails?.current_plan?.toString().includes("Pro") ||
    userAccountDetails?.current_plan?.toString().includes("hobby");

  const setHeight = () => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight * 0.01}px`
    );
  };

  window.addEventListener("resize", setHeight);
  window.addEventListener("orientationchange", setHeight);
  setHeight();

  const default_sales_channel_ids =
    userAccountDetails?.default_sales_channel_ids;

  useEffect(() => {
    var selectedStoreIni = selectedStores?.name?.substring(0, 1);
    const cleanData =
      salesChannelsDetails &&
      salesChannelsDetails.map((item) => {
        return {
          id: item.sales_channel_id,
          name: item?.sales_channel_shop_name
            ? item?.sales_channel_shop_name
            : item?.sales_channel_owner_name,
          isChecked:
            default_sales_channel_ids &&
            default_sales_channel_ids.includes(item?.sales_channel_id)
              ? true
              : false,
          sales: 2346,
          hasWriteScope: item.sales_channel_has_write_scope,
          hasAllScope: item.sales_channel_has_all_scope,
          avatar: avatarPic,
          imageUrl: item?.sales_channel_shop_image_url,
          isShop: item?.sales_channel_shop_id ? true : false,
        };
      });

    //set the store
    setStoreList(cleanData);
    //set the selected store
    cleanData.map((child) => {
      if (
        default_sales_channel_ids &&
        default_sales_channel_ids.includes(child?.id)
      ) {
        // setSelectedStore(child);
        setStoreSelected(child);
      }
    });
  }, [default_sales_channel_ids, salesChannelsDetails]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const togglePopover = () => {
    !isPopoverOpen && setIsPopoverOpen(!isPopoverOpen);
  };

  const productSelectorRef = useRef();
  const [isSelectorActive, setIsSelectorActive] = useState(false);
  const handleProductSelectorClick = () => {
    setIsSelectorActive(true);
  };
  function callTour() {
    startObjectsIntroTooltip(handleProductSelectorClick, authDispatch);
  }

  const handleMouseEnter = () => {
    if (sidebar_onboarding_shown === false && isExpanded === true) { 
      callTour();
    }
  }

  const handleMouseLeave = () => {
  }

  return (
    // <MainDiv>
    <StyledWrapper
      isExpanded={isExpanded}
      isUser={isUser}
      matches={matches}
      trialHeight={trialData.on_free_trial}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="fixed-relative">
        <div className="sidebar-main">
          {isExpanded && (
            <>
              {/* <motion.div
              className="logo-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              <Image src={logoIcon} alt="EverBee logo without EverBee" />{" "}
            </motion.div> */}
              <motion.div
                className="product-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.2 } }}
              >
                <div className="product-selecter" ref={productSelectorRef}>
                  <Image
                    src={productSelector}
                    alt="product selecter menu"
                    onClick={handleProductSelectorClick}
                  />
                </div>
                <div
                  className="product-logo"
                  onClick={() => router.push("/dashboard")}
                >
                  <Image
                    src={
                      "https://db2l6eppot2a3.cloudfront.net/research-logo.svg"
                    }
                    alt="product logo"
                    height="100%"
                    width="150%"
                  />
                </div>
              </motion.div>
            </>
          )}
          {!isExpanded && (
            <>
              {/* <motion.div
              className="logo-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              <Image src={logoIconSm} alt="EverBee logo" />
            </motion.div> */}
              <motion.div
                className="product-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.2 } }}
              >
                <div className="logo-container" ref={productSelectorRef}>
                  <Image
                    src={"https://db2l6eppot2a3.cloudfront.net/bee-logo.png"}
                    alt="product logo"
                    width="30%"
                    height="30%"
                    onClick={() =>
                      !isSelectorActive && setIsSelectorActive(true)
                    }
                  />
                  {isSelectorActive ? (
                    <Image
                      src={arrowUp}
                      alt="arrow up icon"
                      onClick={() => setIsSelectorActive(false)}
                    />
                  ) : (
                    <Image
                      src={arrowDown}
                      alt="down arrow icon"
                      onClick={handleProductSelectorClick}
                    />
                  )}
                </div>
              </motion.div>
            </>
          )}
          <ProductSelector
            isSelectorActive={isSelectorActive}
            setIsSelectorActive={setIsSelectorActive}
            productSelectorRef={productSelectorRef}
          />
          {!hideCollapse && (
            <SidebarSwitch
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
            />
          )}

          {!isExpanded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3 } }}
            >
              <StoreSelect
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                selectedStores={selectedStores}
                storeList={storeList}
                setStoreSelected={setStoreSelected}
              />
              <ToggleGroup
                isExpanded={isExpanded}
                alignment={alignment}
                setAlignment={setAlignment}
                setIsExpanded={setIsExpanded}
              />
            </motion.div>
          )}
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              <StoreSelect
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                selectedStores={selectedStores}
                storeList={storeList}
                setStoreSelected={setStoreSelected}
              />
              <ToggleGroup
                isExpanded={isExpanded}
                alignment={alignment}
                setAlignment={setAlignment}
                setIsExpanded={setIsExpanded}
              />
            </motion.div>
          )}
          {alignment == "left" && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
              className="nav-sidebar-inner-scroll"
            >
              <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.DASHBOARD}
                navIcon={dashboardIcon}
                navText="Dashboard"
                menuItemName={MAIN_MENU.DASHBOARD}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />

              <SideMainMenu
                isExpandable={true}
                isActive={menuState == MAIN_MENU.RESEARCH_TOOLS}
                navIcon={researchTools}
                navText="Research Tools"
                menuItemName={MAIN_MENU.RESEARCH_TOOLS}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />
              <AnimatePresence>
                {isExpanded && menuState == MAIN_MENU.RESEARCH_TOOLS && (
                  <motion.div
                    style={{ overflow: "hidden" }}
                    variants={animationOptions}
                    initial="initialOptions"
                    animate="animateOptions"
                    exit="exitOptions"
                    transition={{
                      duration: 0.2,
                    }}
                  >
                    <SideSubMenu
                      parentMainMenu={MAIN_MENU.RESEARCH_TOOLS}
                      subMenuList={SUB_MENU_LIST.RESEARCH_TOOLS}
                      setMenuState={setMenuState}
                      menuState={menuState}
                    />
                  </motion.div>
                )}
              </AnimatePresence>

              {/* <SideMainMenu
                isExpandable={true}
                isActive={menuState == MAIN_MENU.OPTIMIZE}
                navIcon={optimize}
                navText="Optimize"
                menuItemName={MAIN_MENU.OPTIMIZE}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                isUser={isUser}
              />
              <AnimatePresence>
                {isExpanded && menuState == MAIN_MENU.OPTIMIZE && (
                  <motion.div
                    style={{ overflow: "hidden" }}
                    variants={animationOptions}
                    initial="initialOptions"
                    animate="animateOptions"
                    exit="exitOptions"
                    transition={{
                      duration: 0.2,
                    }}
                  >
                    <SideSubMenu
                      parentMainMenu={MAIN_MENU.OPTIMIZE}
                      subMenuList={SUB_MENU_LIST.OPTIMIZE}
                      setMenuState={setMenuState}
                      menuState={menuState}
                      isUser={isUser}
                    />
                  </motion.div>
                )}
              </AnimatePresence> */}

              {/* <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.EVERPRINT}
                navIcon={everPrintIcon}
                navText="EverBee Print"
                menuItemName={MAIN_MENU.EVERPRINT}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                isMenuNew={true}
                setIsExpanded={setIsExpanded}
                selectedStores={selectedStores}
              />
              <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.EVERBEEMAIL}
                navIcon={everEmailIcon}
                navText="EverBee Email"
                menuItemName={MAIN_MENU.EVERBEEMAIL}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                isMenuNew={true}
                setIsExpanded={setIsExpanded}
                selectedStores={selectedStores}
              /> */}
              <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.LEARN}
                navIcon={learnIcon}
                navText="Learn"
                menuItemName={MAIN_MENU.LEARN}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                isMenuNew={true}
                setIsExpanded={setIsExpanded}
              />
            </motion.div>
          )}
          {alignment == "right" && (
            <div className="nav-sidebar-inner-scroll">
              <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.ACCOUNT}
                navIcon={accountsIcon}
                navText="Account"
                menuItemName={MAIN_MENU.ACCOUNT}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />
              <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.TRAINING}
                navIcon={training}
                navText="Training"
                menuItemName={MAIN_MENU.TRAINING}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />
              <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.COURSES}
                navIcon={coursesIcon}
                navText="Courses"
                menuItemName={MAIN_MENU.COURSES}
                setMenuState={setMenuState}
                menuState={menuState}
                isMenuNew={true}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />
              {/* <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.AMBASSADORS}
                navIcon={ambassadorsIcon}
                navText="Ambassadors"
                menuItemName={MAIN_MENU.AMBASSADORS}
                setMenuState={setMenuState}
                menuState={menuState}
                isMenuNew={true}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              /> */}
              <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.AFFILIATE}
                navIcon={affiliateIcon}
                navText="Affiliate Program"
                menuItemName={MAIN_MENU.AFFILIATE}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />
              <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.PRICING}
                navIcon={pricingIcon}
                navText="Plans"
                menuItemName={MAIN_MENU.PRICING}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />
              <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.COMMUNITY}
                navIcon={CommunityIcon}
                navText="Community"
                menuItemName={MAIN_MENU.COMMUNITY}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                isMenuNew={true}
                setIsExpanded={setIsExpanded}
              />
              <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.YOUTUBE}
                navIcon={YoutubeIcon}
                navText="Youtube"
                menuItemName={MAIN_MENU.YOUTUBE}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                isMenuNew={false}
                setIsExpanded={setIsExpanded}
              />
              <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.SUPPORT}
                navIcon={supportIcon}
                navText="Support"
                menuItemName={MAIN_MENU.SUPPORT}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                isMenuNew={false}
                setIsExpanded={setIsExpanded}
              />
              <SideMainMenu
                isExpandable={false}
                isActive={menuState === MAIN_MENU.FEATURE_REQUEST}
                navIcon={featureRequestIcon}
                navText="Feature request"
                menuItemName={MAIN_MENU.FEATURE_REQUEST}
                setMenuState={setMenuState}
                menuState={menuState}
                isExpanded={isExpanded}
                isMenuNew={true}
                setIsExpanded={setIsExpanded}
              />
            </div>
          )}
        </div>

        {userAccountDetails?.current_plan && (
          <div className="sidebar-footer">
            {(trialData.on_free_trial === true ||
              userAccountDetails?.current_plan?.includes("hobby") ||
              userAccountDetails?.current_plan?.includes("Pro")) &&
              (isExpanded ? (
                <div className="upgrade-div">
                  <div>
                    <a href={"/pricing"} rel="noreferrer" target="_blank">
                      <Typography
                        fontSize="12px"
                        fontWeight="500"
                        color="#3D98F2"
                      >
                        Upgrade Plan
                      </Typography>
                    </a>
                  </div>

                  <Image src={up} alt="up-arrow" />
                </div>
              ) : (
                <div className="upgrade-not-expanded">
                  <div>
                    <a href={"/pricing"} rel="noreferrer" target="_blank">
                      <Image src={up} alt="up-arrow" />
                    </a>
                  </div>
                </div>
              ))}
            {trialData.on_free_trial === true && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.2 } }}
              >
                <ProductAnalytics isExpanded={isExpanded} />
              </motion.div>
            )}

            {isExpanded && (
              <>
                <div className="user-info" onClick={togglePopover}>
                  <Avatar
                    sx={{
                      marginRight: "15px",
                      marginLeft: "6px",
                      marginTop: "12px",
                      background: userAccountDetails?.current_plan?.includes(
                        "BOGO"
                      )
                        ? "linear-gradient(to left, #ff9900, #ff6600)"
                        : userAccountDetails?.current_plan?.includes(
                            "Growth"
                          ) ||
                          userAccountDetails?.current_plan?.includes("Business")
                        ? "linear-gradient(to bottom, #FFD251 1.78%, #DAA50F 100%)"
                        : userAccountDetails?.current_plan?.includes("Pro")
                        ? "linear-gradient(to bottom, #C0C0C0 0%, #737373 100%)"
                        : "linear-gradient(to bottom, #b08d57 0%, #b08d57 100%)",
                    }}
                    src={userImageUrl !== null && userImageUrl}
                  >
                    {userImageUrl === null &&
                      everbeeName?.substring(0, 1).toUpperCase()}
                  </Avatar>
                  <div className="profile-container">
                    <div>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "13px",
                          paddingTop: "5px",
                        }}
                      >
                        {!userAccountDetails?.hide_details
                          ? everbeeName
                          : "*********"}
                      </Typography>
                      <Tooltip
                        PopperProps={{ style: { zIndex: 9999999988 } }}
                        title={
                          <p
                            style={{
                              fontWeight: "500",
                              fontSize: "12px",
                            }}
                          >
                            {!userAccountDetails?.hide_details
                              ? everbeeEmail
                              : "********"}
                          </p>
                        }
                        followCursor
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "144px",
                            color: "#2E8AE5",
                          }}
                        >
                          {!userAccountDetails?.hide_details
                            ? everbeeEmail
                            : "********"}
                        </Typography>
                      </Tooltip>
                      <Typography sx={{ fontSize: "12px" }}>
                        {userAccountDetails?.current_plan}
                      </Typography>
                    </div>
                    <div>
                      <Image src={ArrowUpDown} alt="up-dowm" />
                    </div>
                  </div>

                  {isPopoverOpen && (
                    <UserCardInfo
                      isOpen={isPopoverOpen}
                      onClose={() => setIsPopoverOpen(false)}
                    />
                  )}
                </div>

                {/* <PlanViewSideBar
                currentUserPlan={userAccountDetails?.current_plan}
              /> */}
                {!hideCollapse && !tabView && (
                  <motion.div
                    className="add-to-chrome"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: 0.2 } }}
                  >
                    {extInstalled ? (
                      <Image
                        src={AccessExtension}
                        alt="Access Extension"
                        onClick={goToEtsy}
                      />
                    ) : (
                      <Image
                        src={addToChrome}
                        alt="Add to Chrome button"
                        onClick={goToEtsy}
                      />
                    )}
                  </motion.div>
                )}
              </>
            )}

            {!isExpanded && (
              <>
                <Avatar
                  sx={{
                    margin: "11px",
                    background: userAccountDetails?.current_plan?.includes(
                      "BOGO"
                    )
                      ? "linear-gradient(to left, #ff9900, #ff6600)"
                      : userAccountDetails?.current_plan?.includes("Growth") ||
                        userAccountDetails?.current_plan?.includes("Business")
                      ? "linear-gradient(to bottom, #FFD251 1.78%, #DAA50F 100%)"
                      : userAccountDetails?.current_plan?.includes("Pro")
                      ? "linear-gradient(to bottom, #C0C0C0 0%, #737373 100%)"
                      : "linear-gradient(to bottom, #b08d57 0%, #b08d57 100%)",

                    justifyContent: "center",
                    width:
                      !userAccountDetails?.current_plan?.includes("Growth") &&
                      !userAccountDetails?.current_plan?.includes("Business")
                        ? "53%"
                        : trialData.on_free_trial === true
                        ? "53%"
                        : "53%",
                    height:
                      !userAccountDetails?.current_plan?.includes("Growth") &&
                      !userAccountDetails?.current_plan?.includes("Business")
                        ? "30%"
                        : trialData.on_free_trial === true
                        ? "22%"
                        : "37%",
                    borderRadius: "50%",
                    margin: 0,
                    marginLeft: !isExpanded && "16px",
                    marginTop: !isExpanded && "0.7rem",
                  }}
                  src={userImageUrl !== null && userImageUrl}
                >
                  {userImageUrl === null &&
                    everbeeName?.substring(0, 1).toUpperCase()}
                </Avatar>
                {!hideCollapse && (
                  <motion.div
                    className="add-to-chrome"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: 0.2 } }}
                  >
                    <Image
                      src={addToChromeSm}
                      alt="Add to Chrome button collapsed"
                      onClick={goToEtsy}
                    />
                  </motion.div>
                )}
              </>
            )}
          </div>
        )}
      </div>{" "}
    </StyledWrapper>
    // </MainDiv>
  );
};

export default Sidebar;
