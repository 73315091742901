import axios from "axios";

const AUTH_BASE_URL = process.env.NEXT_PUBLIC_SSO_APP;

export const stripeSubscription = (payload) =>
  axios.post(`${AUTH_BASE_URL}/stripe_subscriptions`, payload);

export const updateStripeSubscription = (payload) =>
  axios.put(`${AUTH_BASE_URL}/stripe_subscriptions/ids`, payload);

export const promoCodeDetails = (payload) =>
  axios.get(`${AUTH_BASE_URL}/promo_code/promo_code_details/${payload}`);

export const checkAndUpdateSubscription = () =>
  axios.put(
    `${AUTH_BASE_URL}/stripe_subscriptions/check_and_update_subscription`,
    {}
  );

export const assignTestGroup = (payload) => 
  axios.get(`${AUTH_BASE_URL}/upsell_flow/assign_upsell_group`);

export const acceptUpSellAnnual = (payload) =>
  axios.post(`${AUTH_BASE_URL}/upsell_flow/upsell_to_annual_plan`, payload);

export const threedConfirmPayment = (payload) =>
  axios.post(`${AUTH_BASE_URL}/upsell_flow/confirm_payment`, payload);


